import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import ChevronRightIcon from '../icons/ChevronRight';
import useSettings from '../hooks/useSettings';
import useMounted from '../hooks/useMounted';
import { supplierApi } from '../Api/SupplierApi';

import { getSuppliers, blockSupplier } from '../Api/SupplierApi';
import { useDispatch } from 'react-redux';
import { loadingActions } from '../slice/loading';
import PartnerListTable from '../components/business-partner/PartnerListTable';
import { getPartners } from '../Api/BusinessPartnerApi';
import AgentListTable from '../components/agents/AgentListTable';
import { getAgents } from '../Api/SalesAgentApi';


const SalesAgentManagement =()=>{
	const mounted = useMounted();
  const { settings } = useSettings();
  const [suppliers, setSuppliers] = useState([]);
  const dispatch = useDispatch();

// dummy API
// 	const getSuppliers = useCallback(async () => {
//     try {
//       const data = await supplierApi.getSuppliers();

//       if (mounted.current) {
//         setSuppliers(data);
//       }
//     } catch (err) {
//       console.error(err);
//     }
//   }, [mounted]);

	// main API
	const getSupplier = useCallback(async () => {
		try {
			dispatch(loadingActions.increment());
			const data = await getAgents();

			// if (mounted.current) {
				const newData=data.filter((user)=>!user.isAdmin);
				setSuppliers(newData);
			// }
		} catch (err) {
		console.error(err);
		} finally{
			dispatch(loadingActions.decrement());
		}
	}, [mounted]);
	// main block 
	async function block(supplierId,isBlocked){
		try {
		  dispatch(loadingActions.increment());
		  const response = await blockSupplier(supplierId,isBlocked);
		  if(response && response.status===201){
			setSuppliers(state=>{
			  return state.map(user=>{
				if(user.id == response.data.item.id){
				  return {
					...user,
					isBlocked : response.data.item.isBlocked
				  }
				}
				return user;
			  })
			})
		  }
		  
		} catch (err) {
		  console.error(err);
		}
		finally{
		  dispatch(loadingActions.decrement());
		}
	  };
	
	useEffect(() => {
    	getSupplier();
  	},[getSupplier]);
	
	return(
		<>
			<Box
				sx={{
					backgroundColor: 'background.default',
					minHeight: '100%',
					py: 8
				}}
			>
				<Container maxWidth={settings.compact ? 'xl' : false}>
					<Grid
						container
						justifyContent="space-between"
						spacing={0}
					>
						<Grid item>
							<Typography
								color="textPrimary"
								variant="h3"
							>
								Sales Agent Management
							</Typography>
							<Breadcrumbs
								aria-label="breadcrumb"
								separator={<ChevronRightIcon fontSize="small" />}
								sx={{ mt: 1 }}
							>
								<Link
									color="textPrimary"
									component={RouterLink}
									to="/"
									variant="subtitle2"
								>
									Dashboard
								</Link>
								<Typography
									color="textSecondary"
									variant="subtitle2"
								>
									Sales Agent Management
								</Typography>
							</Breadcrumbs>
						</Grid>
					</Grid>
					<Box sx={{ mt: 3 }}>
						<AgentListTable suppliers={suppliers} blocked={block}/>
					</Box>
				</Container>
			</Box>
		</>
    );
}

export default SalesAgentManagement