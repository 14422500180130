
import ApiRequest from '../Api/ApiRequest';
import requestUrls from '../Environment';
import { REACT_APP_KEY } from '../Environment';
var CryptoJS = require("crypto-js");

const key =REACT_APP_KEY
export async function getAdminDashboard(){
  try{
    
    const response = await ApiRequest.get(requestUrls.getAdminDashboardData);
   
    const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
    const result= JSON.parse(decryptedData);
    console.log("getAdminDashboard",result)
    return Promise.resolve(result);
    // return Promise.resolve(response.data.item);
  }catch(error){
    return Promise.reject(error);
  }
}
export async function getAllTransactions(){
    try{
      const response = await ApiRequest.post(requestUrls.getTransactions);
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }

  export async function getAllCategories(){
    try{
      const response = await ApiRequest.get(requestUrls.viewCategories);
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }
  
  export async function getAllAccreditations(){
    try{
      const response = await ApiRequest.get(requestUrls.viewAccreditations);
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }
  export async function getAllOrdersAdmin(){
    try{
      const response = await ApiRequest.get(requestUrls.getOrdersAdmin);
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }
  export async function insertCategory(
    description,sendImage,msba,cidb,eaab,saqa,sacap,hnbrc,iopsa,pirb,medical,sali
  ){
    try{
      // //console.log(description,msba,cidb,eaab,saqa,sacap,hnbrc,iopsa,pirb,medical,sali);
      const response = await ApiRequest.post(requestUrls.insertCategory,{
        description:description,
        categoryImage:sendImage,
        msba:msba,
        cidb:cidb,
        eaab:eaab,
        saqa:saqa,
        sacap:sacap,
        hnbrc:hnbrc,
        iopsa:iopsa,
        pirb:pirb,
        medical:medical,
        sali:sali
      });
      // //console.log(response);
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }
  export async function updateCategories(
    id,description,sendImage,msba,cidb,eaab,saqa,sacap,hnbrc,iopsa,pirb,medical,sali
  ){
    try{
      // //console.log(id,description,msba,cidb,eaab,saqa,sacap,hnbrc,iopsa,pirb,medical,sali);
      const response = await ApiRequest.post(requestUrls.updateCategories,{
        id:id,
        description:description,
        categoryImage:sendImage,
        msba:msba,
        cidb:cidb,
        eaab:eaab,
        saqa:saqa,
        sacap:sacap,
        hnbrc:hnbrc,
        iopsa:iopsa,
        pirb:pirb,
        medical:medical,
        sali:sali
      });
      // console.log(response);
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
      // console.log(result);
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }

  export async function getCommision(){
    try{
      console.log("getCommision");
      const response = await ApiRequest.get(requestUrls.getCommision);
      console.log(response);
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }
  export async function insertCommision(
    name,commission,pWAPercentage,busPartPercentage,agentPercentage
  ){
    try{
      console.log(name,commission);
      const response = await ApiRequest.post(requestUrls.insertCommision,{
        name:name,
        commission:parseFloat(commission),
        pWAPercentage:parseFloat(pWAPercentage),
        busPartPercentage:parseFloat(busPartPercentage),
        agentPercentage:parseFloat(agentPercentage),
      });
      // //console.log(response);
      // return null
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }
  export async function updateCommision(
    id,name,commission,pWAPercentage,busPartPercentage,agentPercentage
  ){
    try{
      // console.log(pWAPercentage,busPartPercentage,agentPercentage);
      const response = await ApiRequest.post(requestUrls.updateCommision,{
        id:id,
        name:name,
        commission:parseFloat(commission),
        pWAPercentage:parseFloat(pWAPercentage),
        busPartPercentage:parseFloat(busPartPercentage),
        agentPercentage:parseFloat(agentPercentage),
      });
      // //console.log(response);
      // return null
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }
  export async function getRefunds(){
    try{
      const response = await ApiRequest.get(requestUrls.getRefunds);
      // //console.log(response);
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }
  export async function getPayouts(){
    try{
      const response = await ApiRequest.get(requestUrls.getPayouts);
      // //console.log(response);
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }
  export async function getRefundsPaystack(){
    try{
      const response = await ApiRequest.get(requestUrls.getRefundsPaystack);
      // //console.log(response);
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }
  export async function getAdmins(){
    try{
      const response = await ApiRequest.get(requestUrls.getAdmins);
      // //console.log(response);
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }

  export async function insertAdmin(
    email,firstName,lastName,password,
    dashboard,customer,supplier,ticket,transactions,payment,refund,
    horizon,employee,job,category
  ){
    try{
      // //console.log(description,msba,cidb,eaab,saqa,sacap,hnbrc,iopsa,pirb,medical,sali);
      const response = await ApiRequest.post(requestUrls.addAdmin,{
        email:email,
        firstName:firstName,
        lastName:lastName,
        password:password,

        dashboard:dashboard,
        customer:customer,
        supplier:supplier,
        ticket:ticket,
        transactions:transactions,
        payment:payment,
        refund:refund,
        horizon:horizon,
        employee:employee,
        job:job,
        category:category,
 
      });
      // //console.log(response);
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }
  export async function updateAdmin(
    userId,email,firstName,lastName,
    dashboard,customer,supplier,ticket,transactions,payment,refund,
    horizon,employee,job,category
  ){
    try{
      // //console.log("updateAdmin", userId);
      const response = await ApiRequest.post(requestUrls.updateAdmin,{
        userId:userId,
        email:email,
        firstName:firstName,
        lastName:lastName,
        dashboard:dashboard,
        customer:customer,
        supplier:supplier,
        ticket:ticket,
        transactions:transactions,
        payment:payment,
        refund:refund,
        horizon:horizon,
        employee:employee,
        job:job,
        category:category,
      });
      // //console.log(response);
      // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
      // return userId
    }catch(error){
      return Promise.reject(error);
    }
  }