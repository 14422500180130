import {
  formatDistanceToNowStrict,
  subHours,
  subMinutes
} from 'date-fns';
// import numeral from 'numeral';
import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  // CardMedia,
  // Divider,
  Grid,
  // IconButton,
  Link,
  Rating,
  // Tooltip,
  Typography
} from '@mui/material';
import Scrollbar from '../layout/Scrollbar';

const now = new Date();

const projects = [
  {
    id: '5e8dcef8f95685ce21f16f3d',
    author: {
      id: '5e887b7602bdbc4dbb234b27',
      avatar: 'https://images.unsplash.com/photo-1508921912186-1d1a45ebb3c1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80',
      name: 'Jie Yan Song'
    },
    budget: 6125.00,
    caption: 'We\'re looking for experienced Developers and Product Designers to come aboard and help us build succesful businesses through software.',
    currency: '$',
    isLiked: true,
    likes: 7,
    location: 'Europe',
    image: '/static/mock-images/projects/project_1.png',
    rating: 2,
    membersCount: 2,
    title: 'Mella Full Screen Slider',
    type: 'Full-Time',
    updatedAt: subMinutes(now, 24).getTime()
  },
  {
    id: '5e8dcf076c50b9d8e756a5a2',
    author: {
      id: '5e887d0b3d090c1b8f162003',
      avatar: '/static/mock-images/avatars/avatar-omar_darobe.png',
      name: 'Omar Darobe'
    },
    budget: 4205.00,
    caption: 'We\'re looking for experienced Developers and Product Designers to come aboard and help us build succesful businesses through software.',
    currency: '$',
    isLiked: true,
    likes: 12,
    location: 'Europe',
    image: '/static/mock-images/projects/project_2.png',
    rating: 4.5,
    membersCount: 3,
    title: 'Overview Design',
    type: 'Full-Time',
    updatedAt: subHours(now, 1).getTime()
  },
  {
    id: '5e8dcf105a6732b3ed82cf7a',
    author: {
      id: '5e88792be2d4cfb4bf0971d9',
      avatar: '/static/mock-images/avatars/avatar-siegbert_gottfried.png',
      name: 'Siegbert Gottfried'
    },
    budget: 2394.00,
    caption: 'We\'re looking for experienced Developers and Product Designers to come aboard and help us build succesful businesses through software.',
    currency: '$',
    isLiked: true,
    likes: 18,
    location: 'Europe',
    image: '/static/mock-images/projects/project_3.png',
    rating: 4.7,
    membersCount: 8,
    title: 'Ten80 Web Design',
    type: 'Full-Time',
    updatedAt: subHours(now, 16).getTime()
  },
  {
    id: '5e8dcef8f95685ce21f16f3d',
    author: {
      id: '5e887b7602bdbc4dbb234b27',
      avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
      name: 'Jie Yan Song'
    },
    budget: 6125.00,
    caption: 'We\'re looking for experienced Developers and Product Designers to come aboard and help us build succesful businesses through software.',
    currency: '$',
    isLiked: true,
    likes: 7,
    location: 'Europe',
    image: '/static/mock-images/projects/project_1.png',
    rating: 2,
    membersCount: 2,
    title: 'Mella Full Screen Slider',
    type: 'Full-Time',
    updatedAt: subMinutes(now, 24).getTime()
  },
  {
    id: '5e8dcef8f95685ce21f16f3d',
    author: {
      id: '5e887b7602bdbc4dbb234b27',
      avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
      name: 'Jie Yan Song'
    },
    budget: 6125.00,
    caption: 'We\'re looking for experienced Developers and Product Designers to come aboard and help us build succesful businesses through software.',
    currency: '$',
    isLiked: true,
    likes: 7,
    location: 'Europe',
    image: '/static/mock-images/projects/project_1.png',
    rating: 2,
    membersCount: 2,
    title: 'Mella Full Screen Slider',
    type: 'Full-Time',
    updatedAt: subMinutes(now, 24).getTime()
  },
  {
    id: '5e8dcef8f95685ce21f16f3d',
    author: {
      id: '5e887b7602bdbc4dbb234b27',
      avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
      name: 'Jie Yan Song'
    },
    budget: 6125.00,
    caption: 'We\'re looking for experienced Developers and Product Designers to come aboard and help us build succesful businesses through software.',
    currency: '$',
    isLiked: true,
    likes: 7,
    location: 'Europe',
    image: '/static/mock-images/projects/project_1.png',
    rating: 2,
    membersCount: 2,
    title: 'Mella Full Screen Slider',
    type: 'Full-Time',
    updatedAt: subMinutes(now, 24).getTime()
  },
  {
    id: '5e8dcef8f95685ce21f16f3d',
    author: {
      id: '5e887b7602bdbc4dbb234b27',
      avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
      name: 'Jie Yan Song'
    },
    budget: 6125.00,
    caption: 'We\'re looking for experienced Developers and Product Designers to come aboard and help us build succesful businesses through software.',
    currency: '$',
    isLiked: true,
    likes: 7,
    location: 'Europe',
    image: '/static/mock-images/projects/project_1.png',
    rating: 2,
    membersCount: 2,
    title: 'Mella Full Screen Slider',
    type: 'Full-Time',
    updatedAt: subMinutes(now, 24).getTime()
  },
  {
    id: '5e8dcef8f95685ce21f16f3d',
    author: {
      id: '5e887b7602bdbc4dbb234b27',
      avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
      name: 'Jie Yan Song'
    },
    budget: 6125.00,
    caption: 'We\'re looking for experienced Developers and Product Designers to come aboard and help us build succesful businesses through software.',
    currency: '$',
    isLiked: true,
    likes: 7,
    location: 'Europe',
    image: '/static/mock-images/projects/project_1.png',
    rating: 2,
    membersCount: 2,
    title: 'Mella Full Screen Slider',
    type: 'Full-Time',
    updatedAt: subMinutes(now, 24).getTime()
  },
  {
    id: '5e8dcef8f95685ce21f16f3d',
    author: {
      id: '5e887b7602bdbc4dbb234b27',
      avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
      name: 'Jie Yan Song'
    },
    budget: 6125.00,
    caption: 'We\'re looking for experienced Developers and Product Designers to come aboard and help us build succesful businesses through software.',
    currency: '$',
    isLiked: true,
    likes: 7,
    location: 'Europe',
    image: '/static/mock-images/projects/project_1.png',
    rating: 2,
    membersCount: 2,
    title: 'Mella Full Screen Slider',
    type: 'Full-Time',
    updatedAt: subMinutes(now, 24).getTime()
  },
  {
    id: '5e8dcef8f95685ce21f16f3d',
    author: {
      id: '5e887b7602bdbc4dbb234b27',
      avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
      name: 'Jie Yan Song'
    },
    budget: 6125.00,
    caption: 'We\'re looking for experienced Developers and Product Designers to come aboard and help us build succesful businesses through software.',
    currency: '$',
    isLiked: true,
    likes: 7,
    location: 'Europe',
    image: '/static/mock-images/projects/project_1.png',
    rating: 2,
    membersCount: 2,
    title: 'Mella Full Screen Slider',
    type: 'Full-Time',
    updatedAt: subMinutes(now, 24).getTime()
  },
  {
    id: '5e8dcef8f95685ce21f16f3d',
    author: {
      id: '5e887b7602bdbc4dbb234b27',
      avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
      name: 'Jie Yan Song'
    },
    budget: 6125.00,
    caption: 'We\'re looking for experienced Developers and Product Designers to come aboard and help us build succesful businesses through software. dsasid sad ada sd asd asd qwjc wqduj dasjd akjsd  kasjdba jskdas kjasd ',
    currency: '$',
    isLiked: true,
    likes: 7,
    location: 'Europe',
    image: '/static/mock-images/projects/project_1.png',
    rating: 2,
    membersCount: 2,
    title: 'Mella Full Screen Slider',
    type: 'Full-Time',
    updatedAt: subMinutes(now, 24).getTime()
  },
  {
    id: '5e8dcef8f95685ce21f16f3d',
    author: {
      id: '5e887b7602bdbc4dbb234b27',
      avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
      name: 'Jie Yan Song'
    },
    budget: 6125.00,
    caption: 'We\'re looking for experienced Developers and Product Designers to come aboard and help us build succesful businesses through software.',
    currency: '$',
    isLiked: true,
    likes: 7,
    location: 'Europe',
    image: '/static/mock-images/projects/project_1.png',
    rating: 2,
    membersCount: 2,
    title: 'Mella Full Screen Slider',
    type: 'Full-Time',
    updatedAt: subMinutes(now, 24).getTime()
  },
  {
    id: '5e8dcef8f95685ce21f16f3d',
    author: {
      id: '5e887b7602bdbc4dbb234b27',
      avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
      name: 'Jie Yan Song'
    },
    budget: 6125.00,
    caption: 'We\'resad sd sd s s  jk jj dhjd jhad jahshd shd asjhd jshd  dshdsa sadasjd sad asdajksd ansd akjsd asjkd sdsjad asmkjasd sakjdsakdkjsd sanj,ks dkjasd samnd ,sad aksjd sandhjasdh sanmbjdhvsahjnd nmjsahdvijsa dhjsad sanm dhjsad mbsahjvdjmsa db,jsd snjmd ngha sag jhsd jhs djahs d looking for experienced Developers and Product Designers to come aboard and dsad dsa d asd sad sad asd asd asd a help us build succesful businesses through software.dsadjksa sdsa hjads sad asdsad sad sad asd asd asd asd asd asd asd asd',
    currency: '$',
    isLiked: true,
    likes: 7,
    location: 'Europe',
    image: '/static/mock-images/projects/project_1.png',
    rating: 2,
    membersCount: 2,
    title: 'Mella Full Screen Slider',
    type: 'Full-Time',
    updatedAt: subMinutes(now, 24).getTime()
  },
];

const SupplierReiview=(props)=>{
  const {review}=props;
  const [mainReview, setMainReview] = useState();
  const reviewRating=(rating,review)=>{
    review.map(r=>{
      r.ratings = rating.filter(rat => rat.quoteId == r.quoteId);
    });
    review.map(r=>{
      let sum=0
      for(let i=0;i<r.ratings.length;i++){
        sum=sum+r.ratings[i].value;
      }
      let avg=sum/r.ratings.length
      return r.avgRating = avg
    })
    setMainReview(review);
  }
  useEffect(()=>{
    reviewRating(review.ratings,review.reviews);
  },[review])
    return(
      <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        p: 3
      }}
    >
      <Grid
        container
        spacing={3}
      >
        {mainReview && mainReview.map((review) => (
          
          <Grid
            item
            key={review.id}
            md={6}
            xs={12}
          >
            <Card sx={{maxHeight:'500px'}}>
            
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mt: 2
                  }}
                  >
                  <Avatar src={review.profilePicture} />
                  <Box sx={{ ml: 2 }}>
                    <Rating
                      readOnly
                      size="medium"
                      value={review.avgRating}
                      />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      >
                      by
                      {' '}
                      <Link
                        color="textPrimary"
                        variant="subtitle2"
                        >
                        {review.name}
                      </Link>
                      {/* {' '}
                      | Updated
                      {' '}
                      {formatDistanceToNowStrict(review.updatedAt)}
                      {' '}
                      ago */}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  pb: 2,
                  px: 3,
                }}
              >
               
                <Typography
                  color="textSecondary"
                  variant="body2"
                  // sx={{height:'100px'}}
                >
                   <Scrollbar>
                  {review.review}
                  </Scrollbar>
                </Typography>
                
              </Box>
              <Grid
                container
                spacing={3}
              >
                {review.ratings.map((rating)=><Grid 
                  item
                  md={4}
                >
                  <Typography
                     color="textSecondary"
                     variant="body2"
                     sx={{
                      ml:3
                    }}
                  >
                    {rating.ratingTitle}
                  </Typography>
                  <Rating
                      readOnly
                      size="medium"
                      value={rating.value}
                      sx={{
                        ml:2
                      }}
                  />
                </Grid>)}
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
    );
}

export default SupplierReiview