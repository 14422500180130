import ApiRequest from './ApiRequest';
import requestUrls from '../Environment';
import { REACT_APP_KEY } from '../Environment';
var CryptoJS = require("crypto-js");

const key =REACT_APP_KEY
export async function getPartners(){
  try{
    const response = await ApiRequest.get(requestUrls.partner);
          // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
  }catch(error){
    return Promise.reject(error);
  }
}
export async function getPartner(id){
  try{
    const response = await ApiRequest.get(requestUrls.partnerDetails,{
      params: {
        businessPartnerId: id
      }});
    // return Promise.resolve(response.data.item.data);
    const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
    const result= JSON.parse(decryptedData);
    console.log(result)
    return Promise.resolve(result[0]);
  }catch(error){
    return Promise.reject(error);
  }
}

export async function verifyBusinessPartner(id,){
  try{
    const response = await ApiRequest.get(requestUrls.verifyPartner,{
      params:{
        businessPartnerId:id,
        isVerified:true
      }
    })
    return Promise.resolve(response);
  }catch(error){
    //console.log(error);
  }
}
