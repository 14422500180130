
import axios from 'axios';
import requestUrls from '../Environment';
import ApiRequest from './ApiRequest';
import { REACT_APP_KEY } from '../Environment';
var CryptoJS = require("crypto-js");

const key =REACT_APP_KEY
class LoginApi {
  
    async getUser(email,password){
      try{
      const response= await axios.get(requestUrls.login,{params:{ emailId: email, password: password}});
      // console.log("loginApi try",response);
      // if(response.status===200 && response.data.item.isAdmin){
        if(response.status===200){
          const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
          const result= JSON.parse(decryptedData);
          console.log('token', result.token);
          localStorage.setItem('token',result.token);
          // console.log('token', result);
          // dispatch(slice.actions.login(response.data.item));
          return Promise.resolve(result);
        }
      }catch(error){
        //console.log("loginApi catch",error);
        return Promise.reject(error);
      }
    }
  }
  
  export const loginApi = new LoginApi();

export async function getMetaData(id){
    try{
      const response = await ApiRequest.get(requestUrls.meta);
            // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
    }catch(error){
      //console.log(error);
    }
}
  