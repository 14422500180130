import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import requestUrls from '../Environment';
import { loginApi } from '../Api/LoginApi';
import { loadingActions } from './loading';
import { toast } from 'react-toastify';
import { Navigate } from "react-router";

const initialState = {
    userName:localStorage.getItem('userName'),
    userEmail:localStorage.getItem('userEmail'),
    userProfilePic:localStorage.getItem('userProfilePic'),
    isAuth:localStorage.getItem('isAuth'),
    id:localStorage.getItem('id'),

    // dashboard:localStorage.getItem('dashboard'),
    // customer:localStorage.getItem('customer'),
    // supplier:localStorage.getItem('supplier'),
    // ticket:localStorage.getItem('ticket'),
    // transactions:localStorage.getItem('transactions'),
    // payment:localStorage.getItem('payment'),
    // refund:localStorage.getItem('refund'),
    // horizon:localStorage.getItem('horizon'),
    // employee:localStorage.getItem('employee'),
    // job:localStorage.getItem('job'),
    // category:localStorage.getItem('category'),
    arr:localStorage.getItem('arr'),
    meta:''
  };

const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        login(state,action){
            const data = action.payload;
            if(data.isAdmin==true){
            state.userName = data.userName;
            state.userEmail = data.email;
            state.userProfilePic = data.profilePicture;
            state.isAuth = data.isAdmin;
            state.id= data.userId
            state.arr= [0]

            state.dashboard= data.dashboard
            state.customer= data.customer
            state.supplier= data.supplier
            state.ticket= data.ticket
            state.transactions= data.transactions
            state.payment= data.payment
            state.refund= data.refund
            state.horizon= data.horizon
            state.employee= data.employee
            state.job= data.job
            state.category= data.category
            // const test =[0]
            if(data.dashboard==true){
                state.arr.push(1)
            }

            if(data.customer==true){
                state.arr.push(2)
            }
            if(data.supplier==true){
                state.arr.push(3)
            }
            if(data.ticket==true){
                state.arr.push(4)
            }
            if(data.transactions==true){
                state.arr.push(5)
            }
            if(data.payment==true){
                state.arr.push(6)
            }
            if(data.refund==true){
                state.arr.push(7)
            }
            if(data.horizon==true){
                state.arr.push(8)
            }
            if(data.employee==true){
                state.arr.push(9)
            }
            if(data.job==true){
                state.arr.push(10)
            }
            if(data.category==true){
                state.arr.push(11)
            }

            localStorage.setItem('isAuth',data.isAdmin);
            localStorage.setItem('userName',data.firstName+' '+data.lastName);
            localStorage.setItem('userEmail',data.email);
            localStorage.setItem('userProfilePic',data.profilePicture);
            localStorage.setItem('id',data.userId);
            localStorage.setItem('arr',state.arr);

            
            }
        },
        logout(state,action){
            state.isAuth = action.payload;
            localStorage.clear();
        },
        metaData(state,action){
            state.meta = action.payload;
        }
    }
});

export const {reducer} = authSlice

export const login=(email,password)=>async(dispatch)=>{
    try{
        dispatch(loadingActions.increment());
        // const response= await axios.post(requestUrls.login,{ emailId: email, password: password});
        
        const response = await loginApi.getUser(email,password);
        // console.log("login",response)
        dispatch(authSlice.actions.login(response));
        // navigate.navigate("/")
        // window.location.reload()return <Navigate to="/"/>
        // return <Navigate to="/"/>
    }catch(error){
        // //console.log("login",error)
        Promise.reject(error);
    }finally{
        dispatch(loadingActions.decrement());
    }
    
}

export const authActions = authSlice.actions;

export default authSlice