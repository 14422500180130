import {
    Box,
    // Button,
    Card,
    // CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Breadcrumbs,
    Grid,
    Container,
    Link,
    Tab,
    Tabs,
    Avatar,
    CardMedia,
    CardContent
  } from '@mui/material';

const SupplierCertifications=(props)=>{
        const {supplier} =props
    return(
       <>
        {supplier.map((supplier)=>(<>
               <Card sx={{height:'100% !important'}}>
                 <CardContent>
               <Table>
                <TableBody>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      BEE Certified
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                  <Typography
                      color="textSecondary"
                      variant="h6"
                    >
                     {supplier.beeCertified? <Typography variant="h6" color="green">Yes</Typography>:
                      <Typography variant="h6" color="red">No</Typography>}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                     CIDB Registered
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                  <Typography
                      color="textSecondary"
                      variant="h6"
                    >
                     {supplier.cidbRegistered? <Typography variant="h6" color="green">Yes</Typography>:
                      <Typography variant="h6" color="red">No</Typography>}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      EAAB Registered
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                  <Typography
                      color="textSecondary"
                      variant="h6"
                    >
                     {supplier.eaabRegistered? <Typography variant="h6" color="green">Yes</Typography>:
                      <Typography variant="h6" color="red">No</Typography>}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                     HNBRC Certified
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                  <Typography
                      color="textSecondary"
                      variant="h6"
                    >
                     {supplier.hnbrc? <Typography variant="h6" color="green">Yes</Typography>:
                      <Typography variant="h6" color="red">No</Typography>}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      MBSA Member
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                  <Typography
                      color="textSecondary"
                      variant="h6"
                    >
                     {supplier.mbsaMember? <Typography variant="h6" color="green">Yes</Typography>:
                      <Typography variant="h6" color="red">No</Typography>}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      SACAP Registered
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                  <Typography
                      color="textSecondary"
                      variant="h6"
                    >
                     {supplier.sacapRegistered? <Typography variant="h6" color="green">Yes</Typography>:
                      <Typography variant="h6" color="red">No</Typography>}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      SALI Member
                    </Typography>
                  </TableCell>
                  
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      {supplier.saliMember? <Typography variant="h6" color="green">Yes</Typography>:
                      <Typography variant="h6" color="red">No</Typography>}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}} >
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      SAQA Certified
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                  <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      {supplier.saqaCertified? <Typography variant="h6" color="green">Yes</Typography>:
                      <Typography variant="h6" color="red">No</Typography>}
                    </Typography>
                  </TableCell>
                </TableRow>
                </TableBody>
                </Table>
                </CardContent>
                </Card>

            </>
          ))
          }
       </>
    )
}
export default SupplierCertifications