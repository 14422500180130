import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './AgentListTable.css';
// import numeral from 'numeral';
// import PropTypes from 'prop-types';
// import Label from '../Label';
import { Button, MenuList } from '@mui/material';
import {
  Avatar,
  Box,
//   Button,
  Card,
//   Checkbox,
  // Divider,
  IconButton,
  InputAdornment,
  Link,
//   Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
//   Tabs,
  TextField,
  Typography,
  Dialog,
  Container,
  // ListItemIcon,
  // ListItemText
} from '@mui/material';
// import BanIcon from '../../icons/Ban';
// import EyeIcon from '../../icons/Eye';
import SearchIcon from '../../icons/Search';
import Scrollbar from '../layout/Scrollbar';
import DotsHorizontal from '../../icons/DotsHorizontal'
import { Menu, MenuItem } from '@mui/material';
import { alpha } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/WarningOutlined';

const filterOptions = [
  {
    label:'All',
    value:'all'
  },
	{
		label:'Active',
		value:'active',
	},
  {
    label: 'Blocked',
    value: 'blocked'
  },
  {
		label:'Verified',
		value:'verified',
	},
  {
    label: 'Not Verified',
    value: 'notVerified'
  },
];


const applySearch = (suppliers, query) => suppliers
  .filter((supplier) => {
    let matches = true;
    if (query) {
      const properties = ['ownerName', 'companyName'];
      let containsQuery = false;
      properties.forEach((property) => {
        if (supplier[property] &&supplier[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }
    return matches;
  });

const applyPagination = (suppliers, page, limit) => suppliers
  .slice(page * limit, page * limit + limit);



const AgentListTable=(props)=>{

	const { suppliers,blocked, ...other } = props;
	// pagination initials
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);

	//used for seacrh
	const [query, setQuery] = useState('');

	// handle menu 
	const [isBlocked, setIsBlocked] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [alertOpen,setAlertOpen]= useState(false);
  const [supplierId,setSupplierId] = useState();
	const [filter,setFilter] = useState(null);
  const [selectedId, setSelectedId] = useState();

	// handle function
	const appplyFilter = (suppliers,filter)=> {
    if(filter==="blocked"){
      return suppliers.filter((supplier)=>supplier.isBlocked);
    }
    else if(filter==="active"){
      return suppliers.filter((supplier)=>!supplier.isBlocked);
    }
    else if(filter==="verified"){
      return suppliers.filter((supplier)=>supplier.isVerified);
    }
    else if(filter==="notVerified"){
      return suppliers.filter((supplier)=>!supplier.isVerified);
    }
    else{
      return suppliers
    }

  }
	const handleFilterChange =(event)=>{
		setFilter(event.target.value)
	}
	const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

	const handleAlertOpen=()=>{
    setAlertOpen(true);
    setAnchorEl(null);
  };

	const handleAlertClose=(event,b)=>{
    if(b){
      blocked(supplierId,isBlocked);
    }
    
    setAlertOpen(false);
  };

	const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

	const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleClick=(event,isBlocked,id)=>{
    // (status==="active")?setIsBlocked(false):setIsBlocked(true);

    (isBlocked !== true) ? setIsBlocked(false) : setIsBlocked(true);
    setSupplierId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose=()=>{
    setAnchorEl(null);
  };

	const SearchedSuppliers = applySearch(suppliers, query );
	const filteredSuppliers = appplyFilter(SearchedSuppliers, filter); 
  const paginatedSuppliers = applyPagination(filteredSuppliers, page, limit);

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder="Search supplier"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            width: 240
          }}
        >
          <TextField
            label="Filter by status"
            name="Filter"
            onChange={handleFilterChange}
            select
            SelectProps={{ native: true }}
            value={filter}
            variant="outlined"
          >
            {filterOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>
      </Box>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                      Company Name
                  </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="h6">
                       Owner
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="h6">
                        Owner No
                    </Typography>
                </TableCell>

                <TableCell>
                    <Typography variant="h6" align="center">
                        Status
                    </Typography>
                </TableCell>
                <TableCell align="right">
                    <Typography variant="h6">
                        Actions
                    </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedSuppliers.map((supplier) => {
                return (
                  <TableRow
                    hover
                    key={supplier.supplierId}
                    size="small"
                  >
                    <TableCell size="small">
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          src={supplier.logo}
                          sx={{
                            height: 42,
                            width: 42
                          }}
                        >
                        </Avatar>
                        <Box sx={{ ml: 1 }}>
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to={`/sales-agent-management/details/${supplier.id}`}
                            variant="subtitle2"
                          >
                            <Typography variant="body1">
                              {supplier.companyName}
                            </Typography>
                          </Link>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell size="small">
                        <Typography variant="body1">
                            {supplier.owner}
                        </Typography>
                    </TableCell>
                    <TableCell size="small">
                        <Typography variant="body1">
                          {supplier.mobile}
                        </Typography>
                    </TableCell>

                    <TableCell align="center" padding="none" size="small" sx={{width:'100px'}}>
                      {supplier.isVerifiedPartner ===true ?
                        <>
                          {supplier.isBlocked !== true && <Typography variant="h6" color="green">Active</Typography>}
                          {supplier.isBlocked === true && <Typography variant="h6" color="red">Blocked</Typography>}
                        </>
                        :<Typography variant="h6" color="green">Pending Approval</Typography>
                      }
                    </TableCell>
                    <TableCell align="right" sx={{width:'200px'}}>
                      <IconButton
                        aria-controls="simple-menu" 
                        aria-haspopup="true"
                        onClick={(e,s)=>handleClick(e,supplier.isBlocked,supplier.id)}
                      >
                        <DotsHorizontal />
                      </IconButton>     
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={open}
                        keepMounted
                        onClose={handleClose}
                        elevation={1}
                        // anchorOrigin={{
                        //   vertical:'top',
                        //   horizontal: 'left',
                        // }}
                      >
                        <MenuList>
                          <MenuItem key="view" onClick={handleClose}
                            component={RouterLink}
                            to={`/sales-agent-management/details/${supplierId}`}>
                              View
                            </MenuItem>
                            <MenuItem key="block" onClick={handleAlertOpen}>
                              {isBlocked && <Typography>Unblock</Typography>}
                              {!isBlocked && <Typography>Block</Typography>}
                            </MenuItem>
                        </MenuList>
                      </Menu>      
                      {!isBlocked &&   
                        <Dialog
                          open={alertOpen}
                          onClose={(e,b)=>handleAlertClose(e,false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                         <Container maxWidth="sm">
                           <Box
                              sx={{
                                display: 'flex',
                                pb: 2,
                                pt: 3,
                              }}
                            >
                              <Avatar
                                sx={{
                                  backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                                  color: 'error.main',
                                  mr: 2
                                }}
                              >
                                <WarningIcon />
                              </Avatar>
                              <Box>
                                <Typography
                                  color="textPrimary"
                                  variant="h5"
                                >
                                  Block Supplier
                                </Typography>
                                <Typography
                                  color="textSecondary"
                                  sx={{ mt: 1 }}
                                  variant="body2"
                                >
                                  Are you sure you want to block this Supplier ?
                              </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                py: 1.5
                              }}
                            >
                                <Button 
                                  sx={{ mr: 2 }}
                                  variant="outlined"
                                  onClick={(e,b)=>handleAlertClose(e,false)} color="error">
                                  Cancel
                                </Button>
                                <Button
                                  sx={{
                                    backgroundColor: 'error.main',
                                    '&:hover': {
                                      backgroundColor: 'error.dark'
                                    }
                                  }}
                                  variant="contained"
                                  onClick={(e,b)=>handleAlertClose(e,true)} color="success" autoFocus >
                                  Block
                                </Button>
                            </Box>
                          </Container>
                        </Dialog>
                      }
                      {isBlocked &&   
                        <Dialog
                          open={alertOpen}
                          onClose={(e,b)=>handleAlertClose(e,false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                         <Container maxWidth="sm">
                           <Box
                              sx={{
                                display: 'flex',
                                pb: 2,
                                pt: 3,
                              }}
                            >
                              <Avatar
                                sx={{
                                  backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                                  color: 'error.main',
                                  mr: 2
                                }}
                              >
                                <WarningIcon />
                              </Avatar>
                              <Box>
                                <Typography
                                  color="textPrimary"
                                  variant="h5"
                                >
                                  Unblock Supplier
                                </Typography>
                                <Typography
                                  color="textSecondary"
                                  sx={{ mt: 1 }}
                                  variant="body2"
                                >
                                  Are you sure you want to Unblock this Supplier ?
                              </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                py: 1.5
                              }}
                            >
                                <Button 
                                  sx={{ mr: 2 }}
                                  variant="outlined"
                                  onClick={(e,b)=>handleAlertClose(e,false)} color="error">
                                  Cancel
                                </Button>
                                <Button
                                  sx={{
                                    backgroundColor: 'error.main',
                                    '&:hover': {
                                      backgroundColor: 'error.dark'
                                    }
                                  }}
                                  variant="contained"
                                  onClick={(e,b)=>handleAlertClose(e,true)} color="success" autoFocus >
                                  Unblock
                                </Button>
                            </Box>
                          </Container>
                        </Dialog>
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={filteredSuppliers.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        // rowsPerPage={limit}
        // rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

export default AgentListTable