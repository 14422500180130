import {
	// Box, Card,
	TableCell, Typography
} from "@mui/material";
import { Table, TableRow, TableBody, TableHead, Box, TablePagination } from "@mui/material";
import { useParams } from "react-router";
import { Link as RouterLink } from 'react-router-dom';
import Scrollbar from "../layout/Scrollbar";
import { getSupplierOrders } from "../../Api/SupplierApi";
import useMounted from "../../hooks/useMounted";
import { useEffect, useCallback, useState} from 'react';
import { format } from "date-fns";

const applyPagination = (orderList, page, limit) => orderList
  .slice(page * limit, page * limit + limit);

const SupplierOrders = (props) => {
	const {orderList,supplierId} = props
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};
	const handleLimitChange = (event) => {
		setLimit(parseInt(event.target.value, 10));
	  };
	const mounted = useMounted();
	const paginatedOrders = applyPagination(orderList, page, limit);
	return (
		<>
			{paginatedOrders&& paginatedOrders.length>0?
			 <Scrollbar>
			 <Box sx={{ minWidth: 700 }}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<Typography variant="h6">
							Company name
							</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="h6">
							Customer name
							</Typography>
						</TableCell>
						<TableCell>
                            <Typography variant="h6">
                                Order reference
                            </Typography>
                        </TableCell>
						<TableCell>
							<Typography variant="h6">
							Job description
							</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="h6">
								Service date
							</Typography>
						</TableCell>
						
						<TableCell>
							<Typography variant="h6">
								Job status
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{paginatedOrders && paginatedOrders.map((order) => {
						return (
							<TableRow>
								<TableCell component={RouterLink}
                                    to={`/supplier-management/details/${supplierId}/${order.orderId}`}
									sx={{textDecoration:'none'}}>
								 	<Typography variant="body1">
									 {order.companyName?order.companyName:'-'}
                                    </Typography>
								</TableCell>
								<TableCell>
                                    <Typography variant="body1">
									{order.name?order.name:'-'}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">
                                    {order.orderId?order.orderId:'-'}
                                    </Typography>
                                </TableCell>
								<TableCell>
									<Typography variant="body1">
									{order.description?order.description:'-'}
                                    </Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1">
									{order.serviceDate?format(new Date(order.serviceDate),'dd/MM/yyyy'):'-'}
                                    </Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1">
									{order.jobStatus?order.jobStatus:"-"}
                                    </Typography>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<TablePagination
				component="div"
				count={orderList.length}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleLimitChange}
				page={page}
				rowsPerPage={10}
				rowsPerPageOptions={[10]}
				// rowsPerPage={limit}
				// rowsPerPageOptions={[5, 10, 25]}
			/>
			</Box>
			</Scrollbar>:
			<Typography  
				color="textPrimary"
				variant="h3"
				sx={{ textAlign: 'center', margin:'50px'}}>
				No orders found
			</Typography>
			}

		</>
	);
}

export default SupplierOrders