import {
	// Box, Card,
	TableCell, Typography
} from "@mui/material";
import { Table, TableRow, TableBody, TableHead, Box, TablePagination } from "@mui/material";
import Scrollbar from "../layout/Scrollbar";
import { useEffect, useCallback, useState} from 'react';
const applyPagination = (suppliers, page, limit) => suppliers
  .slice(page * limit, page * limit + limit);

const SupplierEmployeeList =(props)=>{
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	const handleLimitChange = (event) => {
		setLimit(parseInt(event.target.value, 10));
	  };


	const paginatedSuppliers = applyPagination(props.employee, page, limit);
	return (
		<>
			{paginatedSuppliers.length>0?
			 <Scrollbar>
			 <Box sx={{ minWidth: 700 }}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<Typography variant="h6">
								Name
							</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="h6">
								Designation
							</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="h6">
								Email
							</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="h6">
								Mobile
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{paginatedSuppliers && paginatedSuppliers.map((employee) => {
						return (
							<TableRow>
								<TableCell variant="body1">
									{employee.employeeName?employee.employeeName:'-'}
								</TableCell>
								<TableCell variant="body1">
									{employee.designation?employee.designation:'-'}
								</TableCell>
								<TableCell variant="body1">
									{employee.email?employee.email:'-'}
								</TableCell>
								<TableCell variant="body1">
									{employee.mobile?employee.mobile:'-'}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<TablePagination
				component="div"
				count={props.employee.length}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleLimitChange}
				page={page}
				rowsPerPage={10}
				rowsPerPageOptions={[10]}
				// rowsPerPage={limit}
				// rowsPerPageOptions={[5, 10, 25]}
			/>
			</Box>
			</Scrollbar>
			:
			<Typography  
            color="textPrimary"
            variant="h3"
            sx={{ textAlign: 'center', margin:'50px'}}>
                  No employee found
          </Typography>}

		</>
	);
}

export default SupplierEmployeeList