import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Avatar, Box, CardMedia, Chip, Link, Typography, Grid, Card } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';

const SupplierPortfolio = (props) => {
  return (
    <Card 
        sx={{
            p:3,
            borderRadius:'0%',
            }}>
        <CardMedia
          image={props.imageURL}
          sx={{
            backgroundColor: 'background.default',
            height: 200,
            p: 1
          }}
        />
    </Card>
  );
};

export default SupplierPortfolio;
