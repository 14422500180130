import ApiRequest from '../Api/ApiRequest';
import requestUrls from '../Environment';
import { REACT_APP_KEY } from '../Environment';
var CryptoJS = require("crypto-js");

const key =REACT_APP_KEY
export async function getSuppliers(){
  try{
    const response = await ApiRequest.get(requestUrls.supplier);
          // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
  }catch(error){
    return Promise.reject(error);
  }
}
export async function getSupplier(id){
  try{
    const response = await ApiRequest.get(requestUrls.supplierDetails,{
      params: {
        supplierId: id
      }});
    // return Promise.resolve(response.data.item.data);
    const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
    const result= JSON.parse(decryptedData);
    return Promise.resolve(result.data);
  }catch(error){
    return Promise.reject(error);
  }
}

export async function blockSupplier(id,isBlocked){
  try{
    const response = await ApiRequest.get(requestUrls.blockSupplier,{
      params:{
        supplierId:id,
        isBlocked:!isBlocked
      }
    })
    return Promise.resolve(response);
  }catch(error){
    //console.log(error);
  }
}
export async function getSupplierOrders(id){
  try{
    const response = await ApiRequest.get(requestUrls.supplierOrders,{params:{supplierId:id, isSupplier:true}})
          // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
  }catch(error){
    //console.log(error);
  }
}
export async function getEmployee(id){
  try{
    const response = await ApiRequest.get(requestUrls.employee,{params:{id:id}})
          // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
  }catch(error){
    //console.log(error);
  }
}
export async function getSupplierPortfolio(id){
  try{
    const response = await ApiRequest.get(requestUrls.supplierPortfolio,{params:{supplierId:id}})
          // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
  }catch(error){
    //console.log(error);
  }
}
export async function getSupplierReview(id){
  try{
    const response = await ApiRequest.get(requestUrls.supplierReview,{params:{SupplierId:id}})
          // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
  }catch(error){
    //console.log(error);
  }
}

export async function getSupplierOrdersDetalis(Id){
  try{
    const response = await ApiRequest.get(requestUrls.orderDetails,{orderId:Number(Id)});
          // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
  }catch(error){
    //console.log(error);
  }
}

export async function getJob(id){
  try{
    const response = await ApiRequest.get(requestUrls.orders,{params:{mobile:id}})
          // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
  }catch(error){
    //console.log(error);
  }
}
export async function verifySupplier(id,){
  try{
    const response = await ApiRequest.get(requestUrls.verifyProvider,{
      params:{
        supplierId:id,
        isVerified:true
      }
    })
    return Promise.resolve(response);
  }catch(error){
    //console.log(error);
  }
}