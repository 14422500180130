import { add } from "date-fns";

// export const baseURL = "http://localhost:3000/api";

// export const baseURL = "http://13.245.94.132:3000/api";
export const baseURL = "https://api.propertywithalton.co.za/api";
export const REACT_APP_KEY="0b92b48764cbe5e0f0f877dbc9290a83"

export const requestUrls = {
    login:`${baseURL}/Admin/Login`,
    customer:`${baseURL}/User/GetUsers`,
    blockCustomer:`${baseURL}/User/SetUserIsBlocked`, 
// Added
    getAdminDashboardData:`${baseURL}/Admin/GetAdminDashboardData`,
// Added
    partner:`${baseURL}/Admin/GetAllPartners`,
// Added
    partnerDetails:`${baseURL}/Admin/GetPartner`,
// Added
    verifyPartner:`${baseURL}/Admin/VerifyPartner`,
// Added
    verifyProvider:`${baseURL}/Admin/VerifySupplier`,
// Added
    verifyAgent:`${baseURL}/Admin/VerifySalesAgent`,
// Added
    agents:`${baseURL}/Admin/GetAllSalesAgents`,
    // Added
    agentDetails:`${baseURL}/Admin/GetSalesAgent`,
// Added
    supplier:`${baseURL}/Supplier/GetAllSuppliers`,
// Added
    supplierDetails:`${baseURL}/Supplier/getSupplierDetails`,
// Added
    employee:`${baseURL}/Supplier/GetAllEmployees`,
    orders:`${baseURL}/Order/GetOrders`,
    supplierOrders:`${baseURL}/Order/GetOrders`,
    blockSupplier:`${baseURL}/Supplier/UpdateSupplier`,
    updateSupplier:`${baseURL}/Supplier/UpdateSupplier`,
    allTickets:`${baseURL}/Ticket/GetAllTickets`,
    insertTicketMessage:`${baseURL}/Ticket/InsertTicketMessage`,
// Added
    supplierPortfolio:`${baseURL}/Supplier/GetSupplierPortfolio`,
    supplierReview:`${baseURL}/Supplier/GetReviewRatings`,
    getAllmessages:`${baseURL}/Ticket/GetTicketMessages`,
    resolveTicket:`${baseURL}/Ticket/ResolveTicket`,
    orderDetails:`${baseURL}/Order/GetOrderDetail`,
    payout:`${baseURL}/Supplier/Payout`,//reviously using FlutterWave, changing to paystack 
    meta:`${baseURL}/Home/GetMasterData`,
    resetPassword:`${baseURL}/User/ResetPassword`,
    //to be added
// view categories
    viewCategories:`${baseURL}/Home/GetServices`,
// update categories (delete,edit)
    updateCategories:`${baseURL}/Admin/UpdateCategories`,
// add categories
    insertCategory:`${baseURL}/Admin/InsertCategory`,
// view accreditations
    viewAccreditations:`${baseURL}/Home/GetAccreditations`,
// update accreditations (delete,edit)
    updateAccreditation:`${baseURL}/Admin/UpdateAccreditation`,
// add accreditations
    addAccreditation:`${baseURL}/Admin/AddAccreditation`,
// add getTransactions
    getTransactions:`${baseURL}/Order/GetTransactions`,
// add accreditations
    getOrdersAdmin:`${baseURL}/Admin/GetOrders`,

// Added
    getCommision:`${baseURL}/Admin/GetCommision`,
// Added
    insertCommision:`${baseURL}/Admin/InsertCommision`,
// Added
updateCommision:`${baseURL}/Admin/UpdateCommision`,
// Added
    getRefundsPaystack:`${baseURL}/Admin/GetRefunds`,
// Added
    getPayouts:`${baseURL}/Admin/GetPayouts`,
// Added
    getRefunds:`${baseURL}/Admin/GetRefundRequests`,

// Added
    getAdmins:`${baseURL}/Admin/getAdmins`,
// Added
    updateAdmin:`${baseURL}/Admin/updateAdmin`,
// Added
    addAdmin:`${baseURL}/Admin/addAdmin`,

    
}

export default requestUrls
export const REACT_APP_ENABLE_REDUX_DEV_TOOLS=true