import ApiRequest from './ApiRequest';
import requestUrls from '../Environment';
import { REACT_APP_KEY } from '../Environment';
var CryptoJS = require("crypto-js");

const key =REACT_APP_KEY
export async function getAgents(){
  try{
    const response = await ApiRequest.get(requestUrls.agents);
          // return Promise.resolve(response.data.item);
      const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
      const result= JSON.parse(decryptedData);
  
      return Promise.resolve(result);
  }catch(error){
    return Promise.reject(error);
  }
}
export async function getAgent(id){
  try{
    const response = await ApiRequest.get(requestUrls.agentDetails,{
      params: {
        agentId: id
      }});
    // return Promise.resolve(response.data.item.data);
    const decryptedData = CryptoJS.AES.decrypt(response.data.itemNew, key).toString(CryptoJS.enc.Utf8);
    const result= JSON.parse(decryptedData);
    return Promise.resolve(result);
  }catch(error){
    return Promise.reject(error);
  }
}
export async function verifySalesAgent(id,){
  try{
    const response = await ApiRequest.get(requestUrls.verifyAgent,{
      params:{
        agentId:id,
        isVerified:true
      }
    })
    return Promise.resolve(response);
  }catch(error){
    //console.log(error);
  }
}
// export async function blockPartner(id,isBlocked){
//   try{
//     const response = await ApiRequest.get(requestUrls.blockSupplier,{
//       params:{
//         SupplierId:id,
//         isBlocked:!isBlocked
//       }
//     })
//     return Promise.resolve(response);
//   }catch(error){
//     //console.log(error);
//   }
// }
