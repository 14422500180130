import {
    Box,
    // Button,
    Card,
    // CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Breadcrumbs,
    Grid,
    Container,
    Link,
    Tab,
    Tabs,
    Avatar,
    CardMedia,
    CardContent
  } from '@mui/material';
  import { useSelector } from 'react-redux';
  import { useState } from 'react';

const SupplierPolicy=(props)=>{
    const {supplier}=props
    const meta = useSelector(state=>state.auth.meta);
    const [policyOption,setPolicyoption] = useState(meta.policyOptions);
    const [policy,setPolicy] = useState(meta.policies);
    return(<>
         <Grid
             container
             spacing={3}
           >
             <Grid
               item
               lg={12}
               md={12}
               xl={12}
               xs={12}
             >
               <Card sx={{height:'100% !important'}}>
                 <CardContent>

                {policy && policy.map((policy)=>{
                return(<><Box sx={{ mt: 3 }}>
                  <Typography
                    variant="h5"
                  >
                    {policy.description}
                  </Typography>
                </Box>
               <Divider />
               <Table>
                <TableBody>
                {supplier[0].policyOptionIds && supplier[0].policyOptionIds.map((id)=>{return(<>
                    {policyOption&&policyOption.map((options)=>{return(<>
                  {options.policyId== policy.id && id==options.id && 
                    <TableRow>
                  <TableCell sx={{borderBottom:"none"}} align="left">
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      {options.description}
                    </Typography>
                  </TableCell>
                </TableRow>}</>)})}
                </>)})}
                </TableBody>
                </Table></>)})}
            </CardContent>
               </Card>
             </Grid>
        </Grid>
    </>);

}
export default SupplierPolicy