import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Link,
    TextField,
    Checkbox,
    Typography,
    FormControlLabel,
    Avatar,
  } from '@mui/material';

  import FormLabel from '@mui/material/FormLabel';
  import Logo from '../pwa_logo.png';
  import { useState, useEffect } from 'react';
  import { useNavigate } from 'react-router';
  import { Navigate } from 'react-router';
  // import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
  import { useDispatch, useSelector } from 'react-redux';
  import { login } from '../slice/auth';
  import { toast } from 'react-toastify';
  import { loadingActions } from '../slice/loading';
  import { useLocation } from "react-router-dom";
  import AWS from 'aws-sdk';
 
  import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { insertCategory, insertCommision, updateCategories } from '../Api/AdminApi';


  const CommisionAdd = () => {

    const [isInValid, setIsInValid] = useState(false);

    const navigate = useNavigate();
    const dispatch= useDispatch();
    // const {state} =useLocation()
    const [name, setName] = useState('');
    const [totalCommission, setTotalCommission] = useState(15);
    const [pWAPercentage, setPWAPercentage] = useState(5);
    const [busPartPercentage, setBusPartPercentage] = useState(5);
    const [agentPercentage, setAgentPercentage] = useState(5);
    // console.log('categoryImage',categoryImage)
    async function updateCategory(commision){
        try{ 
          dispatch(loadingActions.increment());
       
          const data= await insertCommision( name,commision,pWAPercentage,busPartPercentage,agentPercentage)
          console.log("data",data);
          if(data){
            toast.success(name+" has been added ")
            navigate(`/new-horizon-management`)
          }
          else{
            toast.error(data.message);
          }
        }
        catch(error){
            toast.error(error);
            //console.log(error);
        }finally{
          dispatch(loadingActions.decrement());
        }

    }
    // if(isAuth){
    //   return<Navigate to="/"/>
    // }
    

    const onSubmit=async(event)=>{
        event.preventDefault();

        if(name==null||name==''){
          toast.error("Name can not be empty");
     
        }
        if(pWAPercentage==null||pWAPercentage==''){
          toast.error("PWA(%) can not be empty");
     
        }
        if(busPartPercentage==null||busPartPercentage==''){
          toast.error("Business Partner(%) can not be empty");
     
        }
        if(agentPercentage==null||agentPercentage==''){
          toast.error("Sales Agent(%) can not be empty");
     
        }
        else{
          setTotalCommission(agentPercentage+busPartPercentage+pWAPercentage)
          let commision=(parseInt(agentPercentage)+parseInt(busPartPercentage)+parseInt(pWAPercentage))
          // console.log("agentPercentage",agentPercentage)
          // console.log("busPartPercentage",busPartPercentage)
          // console.log("pWAPercentage",pWAPercentage)
          // console.log("commision",commision)
          updateCategory(commision)
        // getUser(email,password);
        }

    }

    return(
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        // p: '120',
        paddingTop:'180px'
      }}
    >
      <Container maxWidth="sm">
        <Card sx={{margin:'auto'}}>

        <CardContent
          sx={{
              backgroundColor:"primary.main",
            display: 'flex',
            flexDirection: 'column',
            p:'0'
          }}
        >
          
            <Box sx={{display:'flex', flexDirection:'inherit', alignItems:'center'}}
            >
              <Typography
                color="white"
                variant="h3"
              >
                Add Commision
              </Typography>
            </Box>
            </CardContent>

            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p:'1'
                }}
        >
            <Box
              sx={{
                flexGrow: 1,
                mt: 2
              }}>
              <form onSubmit={(e)=>onSubmit(e)}>
                <TextField
                  fullWidth
                  label="Comission Name"
                  margin="normal"
                  name="Comission Name"
                  type="default"
                  variant="outlined"
                  value={name}
                  defaultValue={name}
                  onChange={(e)=>setName(e.target.value)}
                  // error={isInValid}
                  // helperText={isInValid? 'Invalid Email' : ' '}
                  sx={{marginBottom:'5px',marginTop:'5px'}}
                />
                <TextField 
                  fullWidth
                  type="number" 
                  label="PWA(%)" 
                  variant="outlined"
                  id="PWA percentage" 
                  onChange={(e)=>setPWAPercentage(e.target.value)}
                  InputProps={{inputProps:{min:0}}}
                  sx={{marginBottom:'5px',marginTop:'5px'}}
                />
                <TextField 
                  fullWidth
                  type="number" 
                  label="Sales Agent(%)" 
                  variant="outlined"
                  id="Sales Agent percentage" 
                  onChange={(e)=>setAgentPercentage(e.target.value)}
                  InputProps={{inputProps:{min:0}}}
                  sx={{marginBottom:'5px',marginTop:'5px'}}
                />
                <TextField 
                  fullWidth
                  type="number" 
                  label="Business Partner(%)" 
                  variant="outlined"
                  id="Business Partner percentage" 
                  onChange={(e)=>setBusPartPercentage(e.target.value)}
                  InputProps={{inputProps:{min:0}}}
                  sx={{marginBottom:'10px',marginTop:'5px'}}
                />
                <Button
                    color="error"
                    fullWidth
                    size="large"
                    // type="submit"
                    variant="contained"
                    onClick={()=> navigate(`/new-horizon-management`)}
                    
                >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
              </form>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
    );
};
  
  export default CommisionAdd;
  