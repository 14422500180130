// import PropTypes from 'prop-types';
import { useEffect, useCallback, useState} from 'react';
import useMounted from '../hooks/useMounted';
import { format } from 'date-fns';
import { supplierApi } from '../Api/SupplierApi';
import {
  Box,
  Button,
  Card,
  // CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Breadcrumbs,
  Grid,
  Container,
  Link,
  Tab,
  Tabs,
  Avatar,
  CardMedia,
  CardContent
} from '@mui/material';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import ChevronRightIcon from '../icons/ChevronRight';
import useSettings from '../hooks/useSettings';
import SupplierOrders from '../components/supplier/SupplierOrders';
import SupplierReiview from '../components/supplier/SupplierReivew';
import SupplierEmployeeList from '../components/supplier/SupplierEmployeeList';
import SupplierPortfolio from '../components/supplier/SupplierPortfolio';
import Scrollbar from '../components/layout/Scrollbar';
import { getSupplier } from '../Api/SupplierApi';
import { getEmployee } from '../Api/SupplierApi';
import { loadingActions } from '../slice/loading';
import { useDispatch } from 'react-redux';
import { getSupplierOrders, getSupplierPortfolio,getSupplierReview } from '../Api/SupplierApi';
import SupplierCertifications from '../components/supplier/SupplierCertifications';
import SupplierPolicy from '../components/supplier/SupplierPolicy';
import { getPartner, verifyBusinessPartner } from '../Api/BusinessPartnerApi';
import { toast } from 'react-toastify';
import { getAgent, verifySalesAgent } from '../Api/SalesAgentApi';
const tabs = [
  { label: 'Sales Agent Details', value: 'details' },
  { label: 'Owner Details', value: 'owner'},
  { label: 'Supplier', value: 'supplier'},
];
const getState=(id)=>{
  switch(id){
    case 1:
      return "Eastern Cape";
    case 2:
      return "Free State";
    case 3:
      return "Gauteng";
    case 4:
      return "KwaZulu-Natal";
    case 5:
      return "Limpopo";
    case 6:
      return "Mpumalanga";
    case 7:
      return "Northern Cape";
    case 8:
      return "North West";
    case 9:
      return "Gujarat";
    default:
      return "no state found";
  }
}

const SalesAgentDetails = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const [isSupplier, setIsSupplier] = useState(false);
  const [supplier, setSupplier] = useState([]);
  const [userData, setUserData] = useState([]);
  const [agent, setAgent] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [currentTab, setCurrentTab] = useState('details');
  const [portfolio,setPortfolio] = useState();
  const [review,setReview] = useState();
  const [date,setDate] =useState();
  const [orders,setOrders] = useState([]);
  const dispatch = useDispatch();

  let {supplierId} = useParams();

  const getSuppliers= useCallback(async () => {
    try {

      dispatch(loadingActions.increment());
      const data = await getAgent(supplierId);
      setUserData(data.user)
      setSupplier(data?.supplier)
      setIsSupplier(data.isSupplier)
      setAgent(data.agent)
      console.log("supplier", data);
      // if (mounted.current) {
        // setSupplier([data]);
      // }
    } catch (err) {
        console.error(err);
      }finally{
        dispatch(loadingActions.decrement());
      }
      
  }, [mounted]);
  const handleSave = async() => {
    try {

      dispatch(loadingActions.increment());
      const data = await verifySalesAgent(supplierId);
      if(data){
        toast.success(data.message)
      
      }
      else{
        toast.error(data.message);
      }
  
      // }
    } catch (err) {
        console.error(err);
      }finally{
        dispatch(loadingActions.decrement());
        getSuppliers();

      }
  };
  const getPortfolio= useCallback(async () => {
    try {

      dispatch(loadingActions.increment());
      const data = await getSupplierPortfolio(supplierId);
      // if (mounted.current) {
        setPortfolio(data);
      // }
    } catch (err) {
        console.error(err);
      }finally{
        dispatch(loadingActions.decrement());
      }
      
  }, [mounted]);
  const getReview= useCallback(async () => {
    try {

      dispatch(loadingActions.increment());
      const data = await getSupplierReview(supplierId);
      // if (mounted.current) {
        setReview(data);
      // }
    } catch (err) {
        console.error(err);
      }finally{
        dispatch(loadingActions.decrement());
      }
      
  }, [mounted]);


  const getEmployees= useCallback(async () => {
    try {
      dispatch(loadingActions.increment());
      const data = await getEmployee(supplierId);
  
      // if (mounted.current) {
        setEmployee(data);
      // }
    } catch (err) {
        console.error(err);
      }finally{
        dispatch(loadingActions.decrement());
      }
      
  }, [mounted]);

  const getOrder= useCallback(async () => {
    try {

      dispatch(loadingActions.increment());
      const data = await getSupplierOrders(supplierId);
  
      // if (mounted.current) {
        setOrders(data);
      // }
    } catch (err) {
        console.error(err);
      }finally{
        dispatch(loadingActions.decrement());
      }
      
  }, [mounted]);
  
  useEffect(() => {
    getSuppliers();
    // getEmployees();
    // getOrder();
    // getPortfolio();
    // getReview();

  }, [getSupplier]);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const filteredTabs = isSupplier
  ? tabs
  : tabs.filter((tab) => tab.value !== "supplier");
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 8
      }}
    >
    <Container maxWidth={settings.compact ? 'xl' : false}>
      <Grid
        container
        justifyContent="space-between"
        spacing={0}
      >
        <Grid item>
          <Typography
            color="textPrimary"
            variant="h3"
          >
            Sales Agent Details
          </Typography>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<ChevronRightIcon fontSize="small" />}
            sx={{ mt: 1 }}
          >
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/"
              variant="subtitle2"
            >
              Dashboard
            </Link>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/supplier-management"
              variant="subtitle2"
            >
              Sales Agent Management
            </Link>
            <Typography
              color="textPrimay"
              variant="subtitle2"
            >
              Sales Agent Details
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        <Card>
        <Box sx={{ mt: 3 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {filteredTabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Scrollbar>
          <Box>
            {/* Agent */}
          {currentTab==="details"&&(
             <Grid
             container
             spacing={2}
           >
             <Grid
               item
               lg={4}
               md={6}
               xl={3}
               xs={12}
             >
               <Card sx={{height:'100% !important'}}>
                 <CardContent>
                   <Box
                     sx={{
                       alignItems: 'center',
                       display: 'flex',
                       flexDirection: 'column',
                       textAlign: 'center'
                     }}
                   >
                     <Box
                       sx={{
                         p: 1,
                         border: (theme) => `1px dashed ${theme.palette.divider}`,
                         borderRadius: '50%'
                       }}
                     >
                       <Avatar
                         src={agent.logo}
                         sx={{
                           height: 200,
                           width: 200
                         }}
                       />
                     </Box>
                     {/* <Typography
                       color="textPrimary"
                       sx={{ mt: 1 }}
                       variant="subtitle2"
                     >
                       {supplier.ownerName}
                     </Typography> */}
                   </Box>
                 {/* </CardContent>
               </Card> */}
                <Box sx={{ mt: 3 }}>
                  <Typography
                    variant="h5"
                  >
                    Agent Information
                  </Typography>
                </Box>
               <Divider />
               <Table>
                <TableBody>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Company Name
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      {agent.companyName}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Owner
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      {userData.ownerName}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Office Number
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      {agent.officeNumber} 
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Email
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none", wordBreak: "break-word"}}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      {userData.email} 
                    </Typography>
                  </TableCell>
                </TableRow>
                
            </TableBody>
            </Table>
            </CardContent>
               </Card>
             </Grid>
             <Grid
                item
                lg={8}
                md={6}
                xl={9}
                xs={12}
              >
                <Card sx={{height:'100%'}}>
                <CardContent>
                <Box sx={{ mt: 3 }}>
                  <Typography
                    variant="h5"
                  >
                    Other Information
                  </Typography>
                </Box>
                <Divider />
                <Table>
              <TableBody>
               
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Registration Number
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      {agent.registrationNumber}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Website
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      sx={{
                        wordBreak:'break-word'
                      }}
                    >
                      {agent.website}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{borderBottom:"none"}} >
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Address
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      {`${userData.addressLine1},${userData.addressLine2}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}} >
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      State
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      {getState(Number(userData.state))}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}} >
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Postal Code
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      {userData.postalCode}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}} >
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Landmark
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      {userData.landmark}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Bank Name
                    </Typography>
                  </TableCell>
                  
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                       {agent.bankName}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Bank Account Number
                    </Typography>
                  </TableCell>
                  
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                       {agent.accountNumber}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{borderBottom:"none"}}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderBottom:"none"}}>
                    {agent.isVerified ===true ?
                      <>
                      {agent.isBlocked !==true &&<Typography
                        color="green"
                        variant="h6"
                      >
                        Active
                      </Typography> }
                      {agent.isBlocked ===true &&<Typography
                        color="red"
                        variant="h6"
                      >
                        Blocked
                      </Typography>}
                      </>
                      :
                      <>
                      <Typography variant="h6" color="orange">Pending Approval</Typography>
                      <Button 
                        // sx={{ mr: 2 }}
                        variant="outlined"
                        onClick={handleSave}
                        color="success"
                        >
                        Approve
                      </Button>
                      
                      </>
                    }
                  </TableCell>
                  
                </TableRow>
                </TableBody>
                </Table>
                </CardContent>
                </Card>
                </Grid>
                </Grid>
       
          )}
          {currentTab==="owner"&&(
               <Grid
               container
               spacing={3}
             >
               <Grid
                 item
                 lg={4}
                 md={6}
                 xl={3}
                 xs={12}
               >
                 <Card sx={{height:'100% !important'}}>
                   <CardContent>
                     <Box
                       sx={{
                         alignItems: 'center',
                         display: 'flex',
                         flexDirection: 'column',
                         textAlign: 'center'
                       }}
                     >
                       <Box
                         sx={{
                           p: 1,
                           border: (theme) => `1px dashed ${theme.palette.divider}`,
                           borderRadius: '50%'
                         }}
                       >
                         <Avatar
                           src={userData.profilePicture}
                           sx={{
                             height: 200,
                             width: 200
                           }}
                         />
                       </Box>
                       {/* <Typography
                         color="textPrimary"
                         sx={{ mt: 1 }}
                         variant="subtitle2"
                       >
                         {supplier.ownerName}
                       </Typography> */}
                     </Box>
                   {/* </CardContent>
                 </Card> */}
                  <Box sx={{ mt: 3 }}>
                    <Typography
                      variant="h5"
                    >
                      Owner Information
                    </Typography>
                  </Box>
                 <Divider />
                 <Table>
                  <TableBody>
                  <TableRow>
                    <TableCell sx={{borderBottom:"none"}}>
                      <Typography
                        color="textPrimary"
                        variant="h6"
                      >
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell sx={{borderBottom:"none"}}>
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        {userData.ownerName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{borderBottom:"none"}}>
                      <Typography
                        color="textPrimary"
                        variant="h6"
                      >
                        Email
                      </Typography>
                    </TableCell>
                    <TableCell sx={{borderBottom:"none", wordBreak: "break-word"}}>
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        {userData.email} 
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{borderBottom:"none"}}>
                      <Typography
                        color="textPrimary"
                        variant="h6"
                      >
                        Mobile
                      </Typography>
                    </TableCell>
                    <TableCell sx={{borderBottom:"none"}}>
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        {userData.mobile} 
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{borderBottom:"none"}}>
                      <Typography
                        color="textPrimary"
                        variant="h6"
                      >
                        Address
                      </Typography>
                    </TableCell>
                    <TableCell sx={{borderBottom:"none"}}>
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                      {`${userData.addressLine1?userData.addressLine1:'-'} ${userData.addressLine2?userData.addressLine2:'-'}`} 
                      </Typography>
                    </TableCell>
                  </TableRow>
                  
              </TableBody>
              </Table>
              </CardContent>
                 </Card>
               </Grid>
               <Grid
                  item
                  lg={8}
                  md={6}
                  xl={9}
                  xs={12}
                >
                  <Card sx={{height:'100%'}}>
                  <CardContent>
                  <Box sx={{ mt: 3 }}>
                    <Typography
                      variant="h5"
                    >
                      Other Information
                    </Typography>
                  </Box>
                  <Divider />
                   <Table>
                <TableBody>
                 
                  <TableRow>
                    <TableCell sx={{borderBottom:"none"}}>
                      <Typography
                        color="textPrimary"
                        variant="h6"
                      >
                        Id Number
                      </Typography>
                    </TableCell>
                    <TableCell sx={{borderBottom:"none"}}>
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        {userData.saId}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{borderBottom:"none"}}>
                      <Typography
                        color="textPrimary"
                        variant="h6"
                      >
                        Passport Number
                      </Typography>
                    </TableCell>
                    <TableCell sx={{borderBottom:"none"}}>
                      <Typography
                        color="textSecondary"
                        variant="body1"
                        sx={{
                          wordBreak:'break-word'
                        }}
                      >
                        {userData.passportNumber}
                      </Typography>
                    </TableCell>
                  </TableRow>
      
  
                  </TableBody>
                  </Table>
                  <Box
  
                   
                  >
                    <Avatar
                      src={userData.idPass}
                       variant="square"
                      sx={{
                        height: 400,
                        width: 400
                      }}
                    />
                  </Box>
                  </CardContent>
                  </Card>
                  </Grid>
                  </Grid>
          )}
          {currentTab==="supplier"&&(
            <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={6}
              xl={3}
              xs={12}
            >
              <Card sx={{height:'100% !important'}}>
                <CardContent>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        p: 1,
                        border: (theme) => `1px dashed ${theme.palette.divider}`,
                        borderRadius: '50%'
                      }}
                    >
                      <Avatar
                        src={supplier.logo}
                        sx={{
                          height: 200,
                          width: 200
                        }}
                      />
                    </Box>
                    {/* <Typography
                      color="textPrimary"
                      sx={{ mt: 1 }}
                      variant="subtitle2"
                    >
                      {supplier.ownerName}
                    </Typography> */}
                  </Box>
                {/* </CardContent>
              </Card> */}
               <Box sx={{ mt: 3 }}>
                 <Typography
                   variant="h5"
                 >
                   Company Information
                 </Typography>
               </Box>
              <Divider />
              <Table>
               <TableBody>
               <TableRow>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textPrimary"
                     variant="h6"
                   >
                     Company Name
                   </Typography>
                 </TableCell>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textSecondary"
                     variant="body1"
                   >
                     {supplier.companyName}
                   </Typography>
                 </TableCell>
               </TableRow>
               <TableRow>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textPrimary"
                     variant="h6"
                   >
                     Owner
                   </Typography>
                 </TableCell>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textSecondary"
                     variant="body1"
                   >
                     {supplier.ownerName}
                   </Typography>
                 </TableCell>
               </TableRow>
               <TableRow>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textPrimary"
                     variant="h6"
                   >
                     Office Number
                   </Typography>
                 </TableCell>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textSecondary"
                     variant="body1"
                   >
                     {supplier.officeNumber} 
                   </Typography>
                 </TableCell>
               </TableRow>
               <TableRow>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textPrimary"
                     variant="h6"
                   >
                     BEE Certified
                   </Typography>
                 </TableCell>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textSecondary"
                     variant="h6"
                   >
                    {supplier.beeCertified? <Typography variant="h6" color="green">True</Typography>:
                     <Typography variant="h6" color="red">False</Typography>}
                   </Typography>
                 </TableCell>
               </TableRow>
               
           </TableBody>
           </Table>
           </CardContent>
              </Card>
            </Grid>
            <Grid
               item
               lg={8}
               md={6}
               xl={9}
               xs={12}
             >
               <Card sx={{height:'100%'}}>
               <CardContent>
               <Box sx={{ mt: 3 }}>
                 <Typography
                   variant="h5"
                 >
                   Other Information
                 </Typography>
               </Box>
               <Divider />
           <Table>
             <TableBody>
              
               <TableRow>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textPrimary"
                     variant="h6"
                   >
                     Registration Number
                   </Typography>
                 </TableCell>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textSecondary"
                     variant="body1"
                   >
                     {supplier.registrationNumber}
                   </Typography>
                 </TableCell>
               </TableRow>
               <TableRow>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textPrimary"
                     variant="h6"
                   >
                     Website
                   </Typography>
                 </TableCell>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textSecondary"
                     variant="body1"
                     sx={{
                       wordBreak:'break-word'
                     }}
                   >
                     {supplier.website}
                   </Typography>
                 </TableCell>
               </TableRow>
               <TableRow>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textPrimary"
                     variant="h6"
                   >
                     hnbrc
                   </Typography>
                 </TableCell>
                 
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textSecondary"
                     variant="body1"
                   >
                     {supplier.hnbrc? <Typography variant="h6" color="green">True</Typography>:
                     <Typography variant="h6" color="red">False</Typography>}
                   </Typography>
                 </TableCell>
               </TableRow>
               <TableRow>
                 <TableCell sx={{borderBottom:"none"}} >
                   <Typography
                     color="textPrimary"
                     variant="h6"
                   >
                     Address
                   </Typography>
                 </TableCell>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textSecondary"
                     variant="body1"
                   >
                     {`${supplier.addressLine1},${supplier.addressLine2}`}
                   </Typography>
                 </TableCell>
               </TableRow>
               <TableRow>
                 <TableCell sx={{borderBottom:"none"}} >
                   <Typography
                     color="textPrimary"
                     variant="h6"
                   >
                     State
                   </Typography>
                 </TableCell>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textSecondary"
                     variant="body1"
                   >
                     {getState(Number(supplier.state))}
                   </Typography>
                 </TableCell>
               </TableRow>
               <TableRow>
                 <TableCell sx={{borderBottom:"none"}} >
                   <Typography
                     color="textPrimary"
                     variant="h6"
                   >
                     Postal Code
                   </Typography>
                 </TableCell>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textSecondary"
                     variant="body1"
                   >
                     {supplier.postalCode}
                   </Typography>
                 </TableCell>
               </TableRow>
               <TableRow>
                 <TableCell sx={{borderBottom:"none"}} >
                   <Typography
                     color="textPrimary"
                     variant="h6"
                   >
                     Landmark
                   </Typography>
                 </TableCell>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textSecondary"
                     variant="body1"
                   >
                     {supplier.landmark}
                   </Typography>
                 </TableCell>
               </TableRow>
               <TableRow>
                 <TableCell sx={{borderBottom:"none"}}>
                   <Typography
                     color="textPrimary"
                     variant="h6"
                   >
                     Status
                   </Typography>
                 </TableCell>

                 
               </TableRow>
               </TableBody>
               </Table>
               </CardContent>
               </Card>
               </Grid>
               </Grid>
          )}
          </Box>
          </Scrollbar>

        </Card>
      </Box>
    </Container>
  </Box>
  );
  
};

export default SalesAgentDetails;
